/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';

import { Paper, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import useMediaQuery from '@mui/material/useMediaQuery';

import 'weather-icons/css/weather-icons.css';

import { DataFrame } from 'data-forge';
import { add, set, parseISO, format } from 'date-fns';

import {
  getAuroraForecast,
  getAuroraForecastScore,
  getAuroraKIndex,
  getForecast,
  getSatellitePasses,
  getTargets,
  getTargetVisibilities
} from '../api/astro';
import TabSet from '../components/Tabs';
import TabPanel from '../components/TabPanel';

import AstroForecastHeatmap from '../components/charts/AstroForecastHeatmap';
import AstroTargetTraces from '../components/charts/AstroTargetTraces';
import AstroTargetsHeatmap from '../components/charts/AstroTargetsHeatmap';
import AuroraKIndexBars from '../components/charts/AuroraKIndexBars';

import {
  address,
  emoji,
  moonPhaseIcon,
  scoreEmoji,
  targetNames,
  visibilityTargetNames
} from '../utils/astroUtils';

import AuroraMap from '../components/charts/AuroraMap';

function Astro() {
  const tabNames = ['Overview', 'Targets', 'Aurora'];

  const [tabValue, setTabValue] = useState(0);

  const [forecast, setForecast] = useState(null);
  const [moonPhase, setMoonPhase] = useState(null);
  const [sunrise, setSunrise] = useState(null);
  const [sunset, setSunset] = useState(null);
  const [visibleTargets, setVisibleTargets] = useState(null);
  // const [issPasses, setIssPasses] = useState(null);

  const [targets, setTargets] = useState(null);
  const [targetVisibilities, setTargetVisibilities] = useState(null);

  const [auroraForecast, setAuroraForecast] = useState(null);
  const [auroraForecastTime, setAuroraForecastTime] = useState(null);
  const [auroraForecastScore, setAuroraForecastScore] = useState(null);
  const [auroraKIndex, setAuroraKIndex] = useState(null);

  const [issPasses, setIssPasses] = useState(null);

  const plotRef = useRef(null);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const processForecast = async () => {
    if (forecast) return;
    console.log(processForecast.name);
    const data = await getForecast(address);
    const df = new DataFrame(data);
    const firstRow = df.first();
    setMoonPhase(firstRow['moon.phase']);
    setSunrise(parseISO(firstRow.sunrise));
    setSunset(parseISO(firstRow.sunset));
    setForecast(df);
  };

  const processTargets = async () => {
    if (targets) return;
    console.log(processTargets.name);
    const data = await getTargets(address, targetNames);
    const df = new DataFrame(data);
    setTargets(df);
  };

  const processTargetVisibilities = async () => {
    if (targetVisibilities) return;
    console.log(processTargetVisibilities.name);
    const sunsetToday = set(new Date(), {
      hours: 16,
      minutes: 30,
      seconds: 0,
      milliseconds: 0
    });
    const sunriseTomorrow = set(add(new Date(), { days: 1 }), {
      hours: 8,
      minutes: 30,
      seconds: 0,
      milliseconds: 0
    });
    const data = await getTargetVisibilities(
      address,
      visibilityTargetNames,
      sunsetToday,
      sunriseTomorrow
    );
    setVisibleTargets(Object.keys(data));
    const dataFrames = Object.fromEntries(
      Object.entries(data).map(([targetName, targetData]) => [
        targetName,
        new DataFrame(targetData)
      ])
    );
    console.log(dataFrames);
    setTargetVisibilities(dataFrames);
  };

  const processAuroraMap = async () => {
    if (auroraForecast && auroraForecastTime) return;
    console.log(processAuroraMap.name);
    const { data, forecast_time: forecastTime } = await getAuroraForecast();
    const df = new DataFrame(data);
    setAuroraForecast(df);
    setAuroraForecastTime(forecastTime);
  };

  const processAuroraScore = async () => {
    if (auroraForecastScore) return;
    console.log(processAuroraScore.name);
    const data = await getAuroraForecastScore(address);
    console.log(data);
    setAuroraForecastScore(data);
  };

  const processAuroraKIndex = async () => {
    if (auroraKIndex) return;
    console.log(processAuroraKIndex.name);
    const data = await getAuroraKIndex(address);
    const df = new DataFrame(data);
    setAuroraKIndex(df);
  };

  const processISSPasses = async () => {
    if (issPasses) return;
    console.log(processISSPasses.name);
    const data = await getSatellitePasses(address, 'ISS (ZARYA)');
    console.log(data);
    setIssPasses(data.passes);
  };

  const tabRequests = {
    0: [
      processForecast,
      processAuroraScore,
      processISSPasses,
      processTargetVisibilities
    ],
    1: [processTargets, processTargetVisibilities],
    2: [processAuroraKIndex, processAuroraMap]
  };

  useEffect(() => {
    const tabName = tabNames[tabValue];
    console.log(tabName);

    const requests = tabRequests[tabValue];
    Promise.all([requests.map((request) => request())]);
  }, [tabValue]);

  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={150} />
    </div>
  );

  const overviewItem = (title, icon, value) => (
    <Grid size={{ xs: 6, md: 3 }}>
      <Typography gutterBottom>{title}</Typography>
      {value ? (
        icon
      ) : (
        <p>
          <CircularProgress size={36} />
        </p>
      )}
      <Typography>{value}</Typography>
    </Grid>
  );

  return (
    <Grid container spacing={5} width="100%">
      <Grid size={12}>
        <TabSet
          tabNames={tabNames}
          onTabChange={(value) => setTabValue(value)}
        />
      </Grid>
      <Grid size={12} style={{ overflowX: 'hidden' }}>
        <TabPanel index={0} value={tabValue}>
          <Grid container spacing={5}>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper ref={plotRef} style={{ padding: 30 }}>
                <Grid container spacing={5}>
                  {overviewItem(
                    'Sunrise',
                    <i
                      className="wi wi-sunrise"
                      style={{ fontSize: 36, color: 'orange' }}
                    />,
                    sunrise ? format(sunrise, 'HH:mm') : ''
                  )}
                  {overviewItem(
                    'Sunset',
                    <i
                      className="wi wi-sunset"
                      style={{ fontSize: 36, color: 'orangered' }}
                    />,
                    sunset ? format(sunset, 'HH:mm') : ''
                  )}
                  {overviewItem(
                    'Moon Phase',
                    <i
                      className={`wi ${moonPhaseIcon[moonPhase]}`}
                      style={{ fontSize: 36, color: 'grey' }}
                    />,
                    moonPhase ?? ''
                  )}
                  {overviewItem(
                    'Visible Targets',
                    <i
                      className="wi wi-stars"
                      style={{ fontSize: 36, color: 'silver' }}
                    />,
                    visibleTargets ? visibleTargets.join(', ') : ''
                  )}
                  {overviewItem(
                    'Aurora score',
                    <p style={{ fontSize: 36 }}>
                      {scoreEmoji(auroraForecastScore?.score)}
                    </p>,
                    auroraForecastScore
                      ? `${auroraForecastScore.score.toFixed(1)}%`
                      : ''
                  )}
                  {overviewItem(
                    'ISS Passes',
                    <p style={{ fontSize: 36 }}>{emoji.satellite}</p>,
                    issPasses ? (
                      <>
                        <div>{`${issPasses.length} in the next 24 hours`}</div>
                        <div>
                          {`Starting at ${format(parseISO(issPasses[0].start), 'HH:mm')}`}
                        </div>
                      </>
                    ) : (
                      ''
                    )
                  )}
                  {/* <p>Meteor showers</p> */}
                </Grid>
              </Paper>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper ref={plotRef}>
                {forecast ? (
                  <AstroForecastHeatmap
                    forecast={forecast}
                    address={address}
                    prefersDarkMode={prefersDarkMode}
                  />
                ) : (
                  progress
                )}
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel index={1} value={tabValue}>
          <Grid container spacing={5}>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>
                <AstroTargetTraces
                  targetVisibilities={targetVisibilities}
                  address={address}
                  prefersDarkMode={prefersDarkMode}
                />
              </Paper>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>
                <AstroTargetsHeatmap
                  targets={targets}
                  address={address}
                  prefersDarkMode={prefersDarkMode}
                />
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel index={2} value={tabValue}>
          <Grid container spacing={5}>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>
                <AuroraKIndexBars
                  auroraKIndex={auroraKIndex}
                  prefersDarkMode={prefersDarkMode}
                />
              </Paper>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>
                <AuroraMap
                  auroraForecast={auroraForecast}
                  auroraForecastTime={auroraForecastTime}
                  auroraForecastScore={auroraForecastScore}
                  prefersDarkMode={prefersDarkMode}
                />
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default Astro;
