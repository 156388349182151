import React from 'react';
import PropTypes from 'prop-types';

import { add, format, parse } from 'date-fns';

import { CircularProgress } from '@mui/material';

import PlotlyAdapter from './PlotlyAdapter';

function AuroraKIndexBars({ auroraKIndex, prefersDarkMode }) {
  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={150} />
    </div>
  );

  if (!auroraKIndex) return progress;

  const df = auroraKIndex.generateSeries({
    date: (row) => parse(row.time_tag, 'yyyy-MM-dd HH:mm:ss.SSS', new Date())
  });

  const traces = [
    {
      type: 'bar',
      x: df.getSeries('date').toArray(),
      y: df.getSeries('Kp').toArray(),
      hoverinfo: 'text',
      hovertext: df
        .map(
          (row) =>
            `${format(row.date, 'EEE do MMM', new Date())}<br />${format(row.date, 'HH:mm', new Date())} - ${format(add(row.date, { hours: 3 }), 'HH:mm', new Date())}<br />Kp: ${row.Kp}`
        )
        .toArray(),
      marker: {
        color: df.getSeries('Kp').toArray(),
        colorscale: 'YlGnBu'
      }
    }
  ];

  const layout = {
    title: `Planetary K-Index<br />3 hourly windows`,
    xaxis: {
      title: ''
    },
    yaxis: {
      title: 'Kp'
    },
    legend: {
      orientation: 'h'
    },
    margin: {
      l: 30,
      r: 30,
      t: 70,
      b: 30
    },
    autosize: true
  };

  const config = {
    displayModeBar: false
  };

  return (
    <PlotlyAdapter
      traces={traces}
      layout={layout}
      config={config}
      prefersDarkMode={prefersDarkMode}
      useResizeHandler
    />
  );
}

AuroraKIndexBars.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  auroraKIndex: PropTypes.any,
  prefersDarkMode: PropTypes.bool
};

AuroraKIndexBars.defaultProps = {
  auroraKIndex: null,
  prefersDarkMode: false
};

export default AuroraKIndexBars;
