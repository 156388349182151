import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Plot from 'react-plotly.js';

import { handlePlotlyDarkMode } from '../../utils/themeUtils';

function CarFuelEconomy({ dataFrame, prefersDarkMode }) {
  const plotRef = useRef(null);
  const [plotWidth, setPlotWidth] = useState(null);

  useEffect(() => {
    if (plotRef?.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth]);

  window.addEventListener('resize', () => {
    if (plotRef?.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  const traces = [
    {
      x: dataFrame.getSeries('filling_date').toArray(),
      y: dataFrame.getSeries('mpg').toArray(),
      name: 'Fuel Economy [mpg]',
      type: 'scatter',
      fill: 'tozeroy',
      showlegend: false,
      hovertemplate: '%{y:.1f} mpg',
      hoverinfo: 'text'
    }
  ];

  const layout = handlePlotlyDarkMode(
    {
      title: '',
      xaxis: {
        title: ''
      },
      yaxis: {
        title: 'Fuel Economy [mpg]'
      },
      width: plotWidth,
      autosize: true
    },
    prefersDarkMode
  );

  return (
    <div ref={plotRef}>
      <Plot data={traces} layout={layout} useResizeHandler />
    </div>
  );
}

CarFuelEconomy.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataFrame: PropTypes.any.isRequired,
  prefersDarkMode: PropTypes.bool
};

CarFuelEconomy.defaultProps = {
  prefersDarkMode: false
};

export default CarFuelEconomy;
