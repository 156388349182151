import React from 'react';
import PropTypes from 'prop-types';

import { parseISO, format } from 'date-fns';

import { CircularProgress } from '@mui/material';

import PlotlyAdapter from './PlotlyAdapter';

function AstroTargetTraces({ targetVisibilities, address, prefersDarkMode }) {
  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={150} />
    </div>
  );

  if (!targetVisibilities) return progress;

  const column = 'Visible altitude [deg]';
  const date = format(new Date(), 'EEE do MMM');

  const traces = Object.entries(targetVisibilities).map(
    ([targetName, targetDf]) => {
      const subDf = targetDf.where((row) => row[column] > 0);

      return {
        type: 'scatter',
        x: subDf.getSeries('Date').toArray(),
        y: subDf.getSeries(column).toArray(),
        name: targetName,
        hoverinfo: 'text',
        hovertext: subDf
          .map(
            (row) =>
              `${format(parseISO(row.Date), 'HH:mm a')}<br />${targetName} ${column
                .toLowerCase()
                .replace('deg', '°')}: ${parseFloat(row[column]).toFixed(0)}`
          )
          .toArray()
      };
    }
  );

  const layout = {
    title: `Target Visibilities - ${date}<br />${address}`,
    xaxis: {
      title: ''
    },
    yaxis: {
      title: column.replace('deg', '°'),
      rangemode: 'nonnegative'
    },
    legend: {
      orientation: 'h'
    },
    margin: {
      l: 70,
      r: 30,
      t: 70,
      b: 30
    },
    autosize: true
  };

  const config = {
    displayModeBar: false
  };

  return (
    <PlotlyAdapter
      traces={traces}
      layout={layout}
      config={config}
      prefersDarkMode={prefersDarkMode}
      useResizeHandler
    />
  );
}

AstroTargetTraces.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  targetVisibilities: PropTypes.any,
  address: PropTypes.string.isRequired,
  prefersDarkMode: PropTypes.bool
};

AstroTargetTraces.defaultProps = {
  targetVisibilities: null,
  prefersDarkMode: false
};

export default AstroTargetTraces;
