const processTargetCode = (code) => {
  const regex = /^(?<catalogue>M|IC|NGC)(?<number>\d+)$/;
  const { catalogue, number } = regex.exec(code).groups;
  return {
    catalogue,
    number
  };
};

const expandTargetCode = (code) => {
  const { catalogue, number } = processTargetCode(code);
  const digits = catalogue === 'M' ? 3 : 4;
  return `${catalogue}${number.padStart(digits, '0')}`;
};

// ToDo: Either pull these from backend, or allow setting in UI.
export const address = 'Great Oldbury, Stroud, United Kingdom';
export const targetNames = [
  'M31',
  'M42',
  'M45',
  'M81',
  'M20',
  'NGC6523',
  'NGC6992',
  'NGC7293',
  'IC1805',
  'NGC7000',
  'M16',
  'IC443',
  'IC1396'
].map(expandTargetCode);
export const visibilityTargetNames = [
  'M31',
  'M42',
  'M45',
  'M81',
  'M101',
  'IC443',
  'NGC2244'
].map(expandTargetCode);

export const emoji = {
  space: '🌌',
  satellite: '🛰️',
  dots: {
    red: '🔴',
    orange: '🟠',
    yellow: '🟡',
    green: '🟢',
    blue: '🔵',
    purple: '🟣'
  }
};

export const moonPhaseIcon = {
  New: 'wi-moon-new',
  'Waxing Crescent': 'wi-moon-waxing-cresent-1',
  'First Quarter': 'wi-moon-first-quarter',
  'Waxing Gibbous': 'wi-moon-waxing-gibbous-4',
  Full: 'wi-moon-full',
  'Waning Gibbous': 'wi-moon-waning-gibbous-4',
  'Third Quarter': 'wi-moon-3rd-quarter',
  'Waning Crescent': 'wi-moon-waning-crescent-5'
};

export const scoreEmoji = (score) => {
  if (score > 90) return emoji.dots.red;
  if (score > 75) return emoji.dots.orange;
  if (score > 50) return emoji.dots.yellow;
  if (score > 10) return emoji.dots.green;
  return emoji.dots.blue;
};
