import React from 'react';
import PropTypes from 'prop-types';

import { parse, parseISO, format, startOfToday } from 'date-fns';
import chroma from 'chroma-js';

import { CircularProgress } from '@mui/material';

import PlotlyAdapter from './PlotlyAdapter';

function AstroForecastHeatmap({ forecast, address, prefersDarkMode }) {
  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={150} />
    </div>
  );

  if (!forecast) return progress;

  const df = forecast
    .generateSeries({
      date: (row) => parseISO(row.date),
      day: (row) => parse(row.day, 'yyyy-MM-dd', new Date()),
      dt: (row) => parseISO(row.dt),
      time: (row) => parse(row.time, 'yyyy-MM-dd HH:mm', new Date()),
      label: (row) =>
        `${format(row.date, 'EEE do MMM')} ${format(
          row.time,
          'HH'
        )}:00<br />Score: ${row['score.custom'].toFixed(2)}`
    })
    .where((row) => row.dt >= startOfToday());

  const nColours = 10;

  const scale = chroma
    .scale([
      'rgb(50, 50, 50)',
      'rgb(255, 0, 0)',
      'rgb(255, 140, 0)',
      'rgb(255, 255, 0)',
      'limegreen'
    ])
    .mode('hsl');

  const colours = scale.colors(nColours);

  const plotlyScale = colours.map((c, i) => [i / (nColours - 1), c]);

  const traces = [
    {
      type: 'heatmap',
      x: df
        .getSeries('hour')
        .toArray()
        .map((h) => `${h}:00`),
      y: df
        .getSeries('date')
        .toArray()
        .map((d) => format(d, 'EEE do MMM')),
      z: df.getSeries('score.custom').toArray(),
      zmin: 0,
      zmax: 1,
      colorscale: plotlyScale,
      name: 'Astrophotography Score',
      text: df.getSeries('label').toArray(),
      hoverinfo: 'text'
    }
  ];

  const layout = {
    showlegend: true,
    xaxis: {
      title: '',
      fixedrange: true
    },
    yaxis: {
      title: '',
      fixedrange: true,
      autorange: 'reversed'
    },
    title: `Forecast Score<br />${address}`,
    autosize: true,
    margin: {
      l: 100
    },
    plot_bgcolor: 'rgb(50, 50, 50)'
  };

  const config = {
    displayModeBar: false
  };

  return (
    <PlotlyAdapter
      traces={traces}
      layout={layout}
      config={config}
      prefersDarkMode={prefersDarkMode}
      useResizeHandler
    />
  );
}

AstroForecastHeatmap.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  forecast: PropTypes.any,
  address: PropTypes.string.isRequired,
  prefersDarkMode: PropTypes.bool
};

AstroForecastHeatmap.defaultProps = {
  forecast: null,
  prefersDarkMode: false
};

export default AstroForecastHeatmap;
