import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';

import PlotlyAdapter from './PlotlyAdapter';

function AstroTargetsHeatmap({ targets, address, prefersDarkMode }) {
  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={150} />
    </div>
  );

  if (!targets) return progress;

  let df = targets.setIndex('Date').dropSeries('Date');

  let scoresToday = [];

  df.getColumnNames().forEach((c) => {
    let series = df.deflate((row) => row[c]);

    const sMin = series.min();
    const sMax = series.max();

    series = series.select((v) => (v - sMin) / (sMax - sMin));

    const dateIndex = `${new Date().toISOString().split('T')[0]}T00:00:00`;
    const scoreToday = series.at(dateIndex);
    scoresToday.push({
      target: c,
      score: scoreToday
    });

    df = df.withSeries({
      [c]: series
    });
  });

  scoresToday = scoresToday.sort((a, b) => a.score - b.score);

  df = df.subset(scoresToday.map((s) => s.target));

  let scores = df.toRows();
  scores = scores[0].map((_, colIndex) => scores.map((row) => row[colIndex]));

  const targetNameStrings = df
    .getColumnNames()
    .map((c) => c.split('|').slice(-1)[0]);

  const traces = [
    {
      type: 'heatmap',
      x: df.getIndex().toArray(),
      y: targetNameStrings,
      z: scores,
      colorscale: 'Hot'
    }
  ];

  const layout = {
    title: `Annual Target Visibility<br />${address}`,
    shapes: [
      {
        type: 'line',
        x0: new Date(),
        x1: new Date(),
        y0: -0.5,
        y1: targetNameStrings.length - 0.5,
        line: {
          color: 'red',
          width: 1
        }
      }
    ],
    margin: {
      l: 160,
      r: 30,
      t: 80,
      b: 40
    },
    autosize: true
  };

  const config = {
    displayModeBar: false
  };

  return (
    <PlotlyAdapter
      traces={traces}
      layout={layout}
      config={config}
      prefersDarkMode={prefersDarkMode}
      useResizeHandler
    />
  );
}

AstroTargetsHeatmap.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  targets: PropTypes.any,
  address: PropTypes.string.isRequired,
  prefersDarkMode: PropTypes.bool
};

AstroTargetsHeatmap.defaultProps = {
  targets: null,
  prefersDarkMode: false
};

export default AstroTargetsHeatmap;
