/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { format, parseJSON } from 'date-fns';

import { CircularProgress } from '@mui/material';

import PlotlyAdapter from './PlotlyAdapter';

import { scoreEmoji } from '../../utils/astroUtils';

function AuroraMap({
  auroraForecast,
  auroraForecastTime,
  auroraForecastScore,
  prefersDarkMode
}) {
  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={150} />
    </div>
  );

  if (!auroraForecast || !auroraForecastTime || !auroraForecastScore)
    return progress;

  const subtext = `Local probability: ${auroraForecastScore.score.toFixed(1)}% ${scoreEmoji(auroraForecastScore.score)}`;

  const column = 'Aurora';
  const date = format(parseJSON(auroraForecastTime), 'H:mm');

  const minThreshold = 10;

  const filteredDf = auroraForecast.where((row) => row[column] >= minThreshold);

  const traces = [
    {
      type: 'scattermap',
      lat: filteredDf.getSeries('Latitude').toArray(),
      lon: filteredDf.getSeries('Longitude').toArray(),
      hoverinfo: 'text',
      hovertext: filteredDf.getSeries('Aurora').toArray(),
      marker: {
        color: filteredDf.getSeries('Aurora').toArray(),
        colorscale: 'Jet',
        cmin: minThreshold,
        cmax: 100,
        cauto: false
      }
    }
  ];

  const layout = {
    title: `Aurora Forecast - ${date}<br />${subtext}`,
    xaxis: {
      title: ''
    },
    yaxis: {
      title: ''
    },
    legend: {
      orientation: 'h'
    },
    map: {
      style: prefersDarkMode ? 'dark' : 'streets',
      center: {
        lat: 51.76,
        lon: -2.31
      },
      zoom: 2
    },
    margin: {
      l: 20,
      r: 20,
      t: 70,
      b: 10
    },
    autosize: true
  };

  const config = {
    displayModeBar: false,
    mapboxAccessToken: process.env.REACT_APP_MAPBOX_TOKEN
  };

  return (
    <PlotlyAdapter
      traces={traces}
      layout={layout}
      config={config}
      prefersDarkMode={prefersDarkMode}
      useResizeHandler
    />
  );
}

AuroraMap.propTypes = {
  auroraForecast: PropTypes.any,
  auroraForecastTime: PropTypes.any,
  auroraForecastScore: PropTypes.any,
  prefersDarkMode: PropTypes.bool
};

AuroraMap.defaultProps = {
  auroraForecast: null,
  auroraForecastTime: null,
  auroraForecastScore: null,
  prefersDarkMode: false
};

export default AuroraMap;
